.ul-main {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 100%;
  max-width: 100%;
  padding-left: 0;
  margin: 0;
  row-gap: calc(25px + 3vw);
}

.li-main {
  width: 50%;
  height: calc(250px + 6vw);
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 300px;
}

.li-main-web3 {
  flex-basis: 100%;
}

.btn-main {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 250px;
  height: 250px;
  text-decoration: none;
}

.btn-main h2 {
  font-size:1.5em;
  margin-bottom: 0px;
}

.home-logo-wrap {
  width: 100%;
}

.home-logo-wrap svg {
  height: 100px;
  margin-bottom: 24px;
}

@media (max-width: 780px) {
  .li-main {
    width: 100%;
  }
  .ul-main {
    row-gap: 0px;
  }
}
