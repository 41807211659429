.superblock-logo-wrap {
  margin: 0 auto;
  width: 250px;
}

.superblock-logo-wrap h1 {
  margin-top: 70px;
}

.project-wrap {
  background-color: white;
  padding: 20px 25px;
  margin-bottom: 5px;
}

.prerequisites li {
  margin-bottom: 5px;
}

.instructions li {
  margin-bottom: 15px;
}



@media (max-width: 500px) {
  .superblock-logo-wrap {
    margin: 0 auto;
    width: 200px;
  }
  .superblock-logo-wrap h1 {
    margin-top: 50px;
  }
}