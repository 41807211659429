.logo-wrap {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.btn-main{
  border: 1px solid var(--primary-color);
  color: var(--quaternary-color);
  background-color: var(--primary-background);
}

.btn-main:hover{
  background-color: var(--primary-color);
  fill: white;
}


.btn-main:hover h2{
  color:white
}
